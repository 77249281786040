@import '~src/scss/helpers/bootstrap_base';

@media print {
    .btn, nav, app-filter, .sidebar, .nav-tabs, ngxh6-filter{
        display: none!important;
    }

    .content-container {
        padding: 0!important;
        box-shadow: none!important;
    }

    .content-fluid {
        padding-top: 0!important;
        padding-bottom: 0 !important;
    }

    .app-wrapper {
        padding-top: 0!important;
    }


    .table{
        th,
        td,
        thead th,
        tbody + tbody {
            border: 0;
        }
    }

}
